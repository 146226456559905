$carousel-thumbs-horizontal-content-disabled-padding: rem-calc(0 7) !default;
$carousel-thumbs-horizontal-content-disabled-padding--large: rem-calc(0 10) !default;
$carousel-thumbs-item-width: rem-calc(70) !default;
$carousel-thumbs-item-width--large: null !default;
$carousel-thumbs-item-height: rem-calc(70) !default;
$carousel-thumbs-item-height--large: null !default;
$carousel-thumbs-item-border: 1px solid color(border) !default;
$carousel-thumbs-item-inactive-opacity: 1 !default;
$carousel-thumbs-item-active-border: 2px solid color(dark) !default;

@mixin lora-components-carousel-thumbs {
    .c-carousel.m-style-thumbs {
        &.m-horizontal {
            .c-carousel__content.m-disabled {
                justify-content: center;
                padding: $carousel-thumbs-horizontal-content-disabled-padding;

                @include breakpoint(large) {
                    padding: $carousel-thumbs-horizontal-content-disabled-padding--large;
                }
            }
        }

        .c-carousel__item {
            overflow: hidden;
            border: $carousel-thumbs-item-border;
            height: $carousel-thumbs-item-height;
            opacity: $carousel-thumbs-item-inactive-opacity;
            width: $carousel-thumbs-item-width;

            @include breakpoint(large) {
                height: $carousel-thumbs-item-height--large;
                width: $carousel-thumbs-item-width--large;
            }

            &.m-current-thumb {
                opacity: 1;
                border: $carousel-thumbs-item-active-border;
            }
        }
    }
}
