$comparisonslider-labels-text-transform: uppercase !default;
$comparisonslider-labels-text-padding: rem-calc(20 20) !default;
$comparisonslider-label-font: 300 #{rem-calc(14)} / 1.25 $font-primary !default;
$comparisonslider-handler-background: color(primary) !default;
$comparisonslider-handler-width: rem-calc(1) !default;
$comparisonslider-handler-icon-size: rem-calc(30) !default;
$comparisonslider-handler-icon-border-radius: 50% !default;
$comparisonslider-handler-icon-background: color(light) !default;
$comparisonslider-handler-icon: round-icon !default;
$comparisonslider-handler-icon-color: color(primary) !default;
$comparisonslider-handler-active-icon-color: color(dark) !default;
$comparisonslider-focus-handler-border: 1px solid color(light) !default;
$comparisonslider-handler-icon-usesvg: false !default;

@mixin lora-components-comparisonslider {
    .c-comparisonslider {
        position: relative;
    }

    .c-comparisonslider__input {
        @include absolute-center;

        appearance: none;
        background: none;
        width: 100%;
        height: 100%;
        margin: 0;
        cursor: pointer;
    }

    .c-comparisonslider__item {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
    }

    .c-comparisonslider__labels {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-transform: $comparisonslider-labels-text-transform;
        padding: $comparisonslider-labels-text-padding;
    }

    .c-comparisonslider__label {
        font: $comparisonslider-label-font;
    }

    .c-comparisonslider__image {
        width: auto;
        max-width: none;
        height: 100%;
    }

    .c-comparisonslider__placeholder {
        width: 100%;
    }

    .c-comparisonslider__handler {
        @include absolute-center;

        cursor: pointer;
        pointer-events: none;
        width: $comparisonslider-handler-width;
        background: $comparisonslider-handler-background;
        height: 100%;

        &::after {
            @include absolute-center;

            content: "";
            width: $comparisonslider-handler-icon-size;
            height: $comparisonslider-handler-icon-size;
            border-radius: $comparisonslider-handler-icon-border-radius;
            background: $comparisonslider-handler-icon-background;
            @include svg-icon($comparisonslider-handler-icon, $comparisonslider-handler-icon-color, 100%, left, no-repeat, $usesvg: $comparisonslider-handler-icon-usesvg);
        }
    }

    .c-comparisonslider__input:active + .c-comparisonslider__handler::after,
    .c-comparisonslider__input:focus + .c-comparisonslider__handler::after {
        border: $comparisonslider-focus-handler-border;
    }

    .c-comparisonslider__input:active + .c-comparisonslider__handler::after {
        @include svg-icon($comparisonslider-handler-icon, $comparisonslider-handler-active-icon-color, 100%, left, no-repeat, $usesvg: $comparisonslider-handler-icon-usesvg, $color-change: true);
    }
}
